import {createSlice, type PayloadAction} from '@reduxjs/toolkit';
import {ICONS_SOURCE} from '@components/icon/constant';
import {SvgClassNameType} from '@components/icon/list';

type StatusCardStateType = {
  message: string;
  variantIcon: keyof typeof ICONS_SOURCE;
  svgClassName?: SvgClassNameType;
  messageClassName: string;
  background: string;
};

const initialState: StatusCardStateType & {
  isOpen: boolean
} = {
  message: '',
  background: '',
  variantIcon: 'warning-hand',
  svgClassName: undefined,
  messageClassName: '',
  isOpen: false,
};

export const statusCardSlice = createSlice({
  name: 'statusCard',
  initialState,
  reducers: {
    openStatusCard: (state, action: PayloadAction<StatusCardStateType>) => {
      state.message = action.payload.message;
      state.variantIcon = action.payload.variantIcon;
      state.svgClassName = action.payload.svgClassName;
      state.messageClassName = action.payload.messageClassName;
      state.background = action.payload.background;
      state.isOpen = true;
    },
    closeStatusCard: () => {
      return initialState
    },
  },
});

export const { openStatusCard, closeStatusCard } = statusCardSlice.actions;
export const statusCardReducer = statusCardSlice.reducer;
