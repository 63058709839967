import React from "react";
import { PermissionRequest } from "../hooks/use-permission";
import { ObjectPermissionContext } from "../types/permission";

export enum PERMISSION_CONTEXT_STATUS {
  NONE = 'NONE',
  LOADING = 'LOADING',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type PermissionContext = {
  objectPermissionContext: ObjectPermissionContext;
  viewListObjectPermissionContext: ObjectPermissionContext;
  permissionContextStatus: PERMISSION_CONTEXT_STATUS;
  setPermissionContextStatus: (status: PERMISSION_CONTEXT_STATUS) => void;
  loadObjectPermissionContext: (permissionRequest: PermissionRequest[]) => void;
};

export const PermissionContext = React.createContext<PermissionContext>({} as PermissionContext);
