
'use client';
import { PermissionContext } from '@/app/contexts/permission-context';
import { IGetCurrentClientResponse } from '@type/clients';
import { ViewListObjectPermissionApi } from '@type/permission';

import { useContext, useEffect } from 'react';

import useFetchCorlPortal from '@hooks/use-fetch-corl-portal';
import { PermissionRequest } from '@hooks/use-permission';
import useUserInfo from '@hooks/use-user-info';

import { API_ENDPOINT } from '@constants/api';

import { setHeaderState } from './redux/slices/header-slice';
import { useAppDispatch } from './redux/store';
import { isEmpty } from 'lodash';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { Spin } from 'antd';
import { getUrl } from '@helpers/common-helpers';
import { ROOT_URL_INDEX, ROUTE } from '@constants/portal';

export default function Home() {
  const permissionContext = useContext(PermissionContext);
  const permissionRequests: PermissionRequest[] = [];
  permissionContext.loadObjectPermissionContext(permissionRequests);

  const router = useRouter();
  const pathName = usePathname();
  const searchParams = useSearchParams();

  const url = getUrl(pathName, searchParams.toString());

  const dispatch = useAppDispatch();
  const { firstName } = useUserInfo();

  const { data: dataCurrentClient, loading: isLoadingCurrentClient } = useFetchCorlPortal<IGetCurrentClientResponse>(
    'current-client',
    undefined,
    { revalidateOnFocus: false },
    false
  );

  const { data: dataPermission, loading: isLoadingPermission } = useFetchCorlPortal<ViewListObjectPermissionApi>(
    `${API_ENDPOINT.CHECK_VIEW_LIST_OBJECT_PERMISSION}?objectTypes=PROJECT`,
    undefined,
    { revalidateOnFocus: false },
    false
  );

  useEffect(() => {
    if (!isEmpty(dataCurrentClient?.currentClient) && dataPermission?.PROJECT && localStorage.getItem('first-login') === 'true') {
      localStorage.setItem('first-login', 'false');
      if (!Object.values(ROUTE).includes(url.split('?')[ROOT_URL_INDEX])) {
        return;
      }
      router.replace(`/projects`);
      return;
    }

    if (!isLoadingPermission && !isLoadingCurrentClient && localStorage.getItem('first-login') === 'false'){
      dispatch(
        setHeaderState({
          title: firstName ? `Welcome ${firstName}` : '',
        })
      );
      localStorage.setItem('first-login', 'false');
    }
    
  }, [dispatch, firstName, dataCurrentClient?.currentClient, dataPermission, router, isLoadingCurrentClient, isLoadingPermission, url]);

  if (isLoadingCurrentClient || isLoadingPermission) {
    return (
      <Spin spinning size="large">
        <div className="h-[100vh]"></div>
      </Spin>
    );
  }
  return <div className="items-center pt-6 px-0 sm:px-6 sm:w-fit sm:m-auto"></div>;
}
