export interface Request {
  method: string;
  body?: string | FormData | URLSearchParams | Blob | ArrayBufferView | ArrayBuffer | ReadableStream<Uint8Array> | null;
}

const fetchWithToken = <T>(url: string, token: string | null, options?: Request, noConvertJson?: boolean): Promise<T> => {
  const absoluteUrl = url ? process.env.NEXT_PUBLIC_PORTAL_API_HOST + url : '';

  if (noConvertJson) {
    return fetch(absoluteUrl, {
      ...(options || {}),
      method: options?.method || 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }) as Promise<T>;
  }

  return fetch(absoluteUrl, {
    ...(options || {}),
    method: options?.method || 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }).then(r => r.json() as Promise<T>);
};
export type TErrorFetch = {
  status: number
  message: string;
  errDetails: unknown;
  errorDetails: object;
};

/**
 * Call API with token and throw error if status code is 4xx or 5xx. The result include status field which is response stastus.
 * 
 * @param apiPath API path, don't start it with '/'. Example: 'projects/1234' 
 * @param token Auth token. If the api is Meditology Portal API, you can get this token from `useAuth0Token` hook
 * @param request request 
 * @returns the parsed json body from server. If the body is empty, it only contains status field. 
 */
export const fetchJSONAPI = async <T>(apiPath: string, token: string | null, request?: Request): Promise<T> => {
  const absoluteUrl = apiPath ? process.env.NEXT_PUBLIC_PORTAL_API_HOST + apiPath : '';

  const r = await fetch(absoluteUrl, {
    ...(request || {}),
    method: request?.method ?? 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  const body = await r.text();
  let resultJson = {};
  if (body) {
    resultJson = { status: r.status, ...JSON.parse(body) } as TErrorFetch;
  } else {
    resultJson = { status: r.status } as TErrorFetch;
  }
  if (r.status >= 400 && r.status < 600) {
    throw resultJson;
  }
  
  return resultJson as T;
};

export default fetchWithToken;
