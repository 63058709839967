"use client";

import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

interface IUseAuth0Token {
  token: string | null;
}

const useAuth0Token = (): IUseAuth0Token  => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = isAuthenticated ? await getAccessTokenSilently() : null;
        setToken(accessToken);
      } catch (error) {
        setToken(null);
      }
    };

    void getToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return {
    token
  }
}

export default useAuth0Token;
