import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export const navigationHistorySlice = createSlice({
  name: 'navigationHistorySlice',
  initialState: {
    toggleNavigation: false,
  },
  reducers: {
    handleToggleNavigation: (state, action: PayloadAction<boolean>) => {
      state.toggleNavigation = action.payload;
    },
  },
})

export const { handleToggleNavigation } = navigationHistorySlice.actions
export const navigationHistoryReducer = navigationHistorySlice.reducer;
