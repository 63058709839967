import { createSlice, type PayloadAction} from '@reduxjs/toolkit';
import { IConfigurationsResponse } from '@type/api';

const initialState: IConfigurationsResponse = {
  defaultPageSize: '10',
  fileSizeLimit: '20'
};

export const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setConfigurations: (state, action: PayloadAction<IConfigurationsResponse>) => {
      state.defaultPageSize = action.payload?.defaultPageSize ?? state.defaultPageSize;
      state.fileSizeLimit = action.payload?.fileSizeLimit ?? state.fileSizeLimit;
    },
  },
});

export const { setConfigurations } = configurationsSlice.actions;
export const configurationsReducer = configurationsSlice.reducer;
