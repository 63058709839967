import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ToastType } from '../../components/toast';
import { TOAST_TYPE } from '../../constants/texts';

type ToastStateType = {
  message?: string;
  type?: ToastType;
};

const initialState: ToastStateType & {
    isOpen: boolean
} = {
  message: '',
  type: TOAST_TYPE.SUCCESS,
  isOpen: false,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast: (state, action: PayloadAction<ToastStateType>) => {
      state.message = action.payload.message;
      state.type = action.payload.type || TOAST_TYPE.SUCCESS;
      state.isOpen = true;
    },
    closeToast: () => {
      return initialState
    },
  },
});

export const { openToast, closeToast } = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
