import {  createSlice, type PayloadAction} from '@reduxjs/toolkit';

type AppStateType = {
  isSessionTimeout: boolean;
}

const initialState: AppStateType = {
  isSessionTimeout: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSessionTimeOut: (state, action: PayloadAction<boolean>) => {
      state.isSessionTimeout = action.payload;
    },
  }
});

export const { setSessionTimeOut } = appSlice.actions;
export const appReducer = appSlice.reducer;
