import { createSlice, type PayloadAction} from '@reduxjs/toolkit';
import { IGetStatusOptionsResponse } from '@type/requests';

const initialState: IGetStatusOptionsResponse = {
  responseStatuses: []
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatusState: (state, action: PayloadAction<IGetStatusOptionsResponse>) => {
      state.responseStatuses = action.payload.responseStatuses;
    },
  },
});

export const { setStatusState } = statusSlice.actions;
export const statusReducer = statusSlice.reducer;
