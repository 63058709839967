import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ReactNode } from 'react';
import { UseBreadcrumbPropType } from '../../components/breadcrumb/use-breadcrumb';

export type HeaderStateType = UseBreadcrumbPropType & {
  title?: string;
  backButtonTitle?: string;
  preButtonTitle?: string;
  onClickPreCallBack?: () => void;
  disabledPreButton?: boolean;
  nextButtonTitle?: string;
  onClickNextCallBack?: () => void;
  disabledNextButton?: boolean;
  isShowBackButton?: boolean;
  isShowPreNextButton?: boolean;
  isShowBreadcrumb?: boolean;
  rightButtonEl?: ReactNode;
  rightHeaderComponent?: ReactNode;
};

const initialState: HeaderStateType = {
  title: '',
  backButtonTitle: '',
  preButtonTitle: '',
  onClickPreCallBack: undefined,
  disabledPreButton: false,
  nextButtonTitle: '',
  onClickNextCallBack: undefined,
  disabledNextButton: false,
  getTextGenerator: undefined,
  isShowBackButton: false,
  isShowPreNextButton: false,
  isShowBreadcrumb: false,
  rightButtonEl: undefined,
  rightHeaderComponent: null,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderState: (state, action: PayloadAction<HeaderStateType>) => {
      state.title = action.payload.title;
      state.backButtonTitle = action.payload.backButtonTitle;
      state.preButtonTitle = action.payload.preButtonTitle;
      state.onClickPreCallBack = action.payload.onClickPreCallBack;
      state.disabledPreButton = action.payload.disabledPreButton;
      state.nextButtonTitle = action.payload.nextButtonTitle;
      state.onClickNextCallBack = action.payload.onClickNextCallBack;
      state.disabledNextButton = action.payload.disabledNextButton;
      state.getTextGenerator = action.payload.getTextGenerator;
      state.isShowBackButton = action.payload.isShowBackButton;
      state.isShowPreNextButton = action.payload.isShowPreNextButton;
      state.isShowBreadcrumb = action.payload.isShowBreadcrumb;
      state.rightButtonEl = action.payload.rightButtonEl;
      state.rightHeaderComponent = action.payload.rightHeaderComponent;
    },
  },
});

export const { setHeaderState } = headerSlice.actions;
export const headerReducer = headerSlice.reducer;
