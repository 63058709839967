import { USER_ACTION } from '@constants/users';
import { ObjectPermissionContext } from '@type/permission';
import querystring from 'querystring';
import { can } from './permission-helper';
import DOMPurify from 'dompurify';
import _ from 'lodash';

export type MenuActionItem = {
  name: string;
  label: string;
  icon?: JSX.Element;
  status: string[];
  action: USER_ACTION;
  disable?: boolean;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const extractPhoneNumber = (phone: string): {
  dialCode: string;
  mainPhone: string;
} => {
  const parts = (phone || '').split(' ');
  if (parts.length === 1) {
    parts.push('');
  }
  return {
    dialCode: parts[0],
    mainPhone: parts[1],
  };
};

export const convertObjectToQueryString = (obj: querystring.ParsedUrlQueryInput) => {
  return '?' + querystring.stringify(obj);
}

export const filterMenuActionItems = (objectPermissionContext: ObjectPermissionContext, actions: MenuActionItem[], status: string, objectId: string): MenuActionItem[] => {
  return actions.filter(
    (item) => {
      return item.status.includes(status) && can(objectPermissionContext, item.action, objectId);
    }
  );
}

export const truncateWithLength = (str: string, maxLength: number): string => {
  if (!str) {
    return '';
  }

  if (str.length <= maxLength) {
    return str;
  }

  return str.slice(0, maxLength) + '...';
}

export const sanitizeHtml = (html: string): string => {
  return DOMPurify.sanitize(html);
}

export function getSessionStorageItem<T>(key: string): T | null {
  const item = sessionStorage.getItem(key);
  return item ? JSON.parse(item) as T : null;
}

export function setSessionStorageItem<T>(key: string, value: T): void {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export const updateSessionStorageItem = <T>(key: string, updateFunction: (item: T) => T) => {
  let item: T = getSessionStorageItem<T>(key) as T;
  item = updateFunction(item);
  setSessionStorageItem(key, item);
};

export const removeSessionStorageItem = (key: string) => {
  sessionStorage.removeItem(key);
};

export const getUrl = (pathName: string, searchParams: string): string => {
  if (!searchParams) return pathName;
  return `${pathName}?${searchParams}`;
}

// To clone nested arrays
export const deepCloneWith = <T> (obj: T): T => {
  return _.cloneDeepWith(obj, (value: T) => {
    if (_.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return value.map(item => _.cloneDeepWith(item));
    }
  }) as T;
}
