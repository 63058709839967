import i18next from 'i18next';
import messeages_en from './messages_en.json';

i18next
  .init({
    lng: 'en',
    resources: {
      'en': {
        translation: messeages_en
      }
    }
  })
  .catch((error) => {
    console.log('Fail to initialize i18next', error);
  });

export const messageResources = i18next;
