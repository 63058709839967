import { messageResources } from '@/app/data/i18n';

export const HEADER_TEXTS = {
  PROJECTS: 'Projects',
  COMPANY_PROFILE: 'Company Profile',
  USERS_MANAGEMENT: 'User Management',
  CLIENT_MANAGEMENT: 'Client Management',
  EVIDENCE_MANAGER: 'Evidence Manager',
  ADD_USER: 'Add User',
  EDIT_USER: 'Edit User',
  ADD_PROJECT: 'Add Project',
  REQUEST_COLLECTION: 'Request Collection',
  ADD_REQUEST_COLLECTION: 'Add Request Collection',
  SEARCH_PRJ_DELIVERABLES: 'Search Project Deliverables',
};

export const SEARCH_TEXTS = {
  PROJECTS: 'Search for Projects',
};

export const VIEW = {
  CARD: 'card',
  LIST: 'list',
};

export const TOAST_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  DISCONNECT: 'disconnect',
} as const;

export const VALIDATION_MESSAGE = {
  WRONG_EMAIL: messageResources.t('INVALID_EMAIL_FORMAT_PROMPT'),
  DUPLICATE_USER_EMAIL: messageResources.t('USER_SAME_EMAIL_EXIST_PROMPT'),
  DUPLICATE_DOC_NAME: 'Duplicate Document Name',
  DOC_NAME_EXISTED: messageResources.t('DOCUMENT_SAME_NAME_PROMPT'),
  FILE_NAME_EXISTED: messageResources.t('FILE_SAME_NAME_PROMPT'),
  EMPTY_DOC_NAME: messageResources.t('ENTER_DOCUMENT_NAME_PROMPT'),
} as const;

export const SWITCH_CLIENT_MESSAGE = {
  SUCCESS: messageResources.t('CLIENT_SWITCHED_SUCCESSFULLY_PROMPT'),
  ERROR: 'Unable to switch client',
} as const;
