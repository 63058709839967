import { PORTAL_OBJECT_TYPE } from "@constants/portal";
import { USER_ACTION } from "../constants/users";
import { ObjectPermissionContext } from "../types/permission";
import _ from 'lodash';

export const DEFAULT_TENANT_ID = '00000000-0000-0000-0000-000000000000';

export const COMMON_PERMISSION_ID = 'COMMON_PERMISSION_ID';

function getObjectTypeByAction(action: USER_ACTION): PORTAL_OBJECT_TYPE | null {
   switch (action) {
    case USER_ACTION.VLU:
    case USER_ACTION.VU:
    case USER_ACTION.MU:
    case USER_ACTION.DU:
    case USER_ACTION.CU:
    case USER_ACTION.CC:
      return PORTAL_OBJECT_TYPE.TENANT;
    case USER_ACTION.VLC:
    case USER_ACTION.VC:
    case USER_ACTION.MC:
    case USER_ACTION.DC:
    case USER_ACTION.CP:
      return PORTAL_OBJECT_TYPE.CLIENT;
    case USER_ACTION.VLP:
    case USER_ACTION.VP:
    case USER_ACTION.MP:
    case USER_ACTION.DP:
    case USER_ACTION.CRC:
    case USER_ACTION.MRC:
      return PORTAL_OBJECT_TYPE.PROJECT;
    default:
      return null;
  }
}

export function can(objectPermissionContext: ObjectPermissionContext, action: USER_ACTION, objectId: string='', objectType?: PORTAL_OBJECT_TYPE): boolean {
  if (_.isEmpty(objectPermissionContext)) return false;

  const calculatedObjectType = objectType ?? getObjectTypeByAction(action);

  if (!calculatedObjectType) return false;

  const permissionByType = objectPermissionContext[calculatedObjectType];

  if (_.isEmpty(permissionByType)) return false;

  return permissionByType[getObjectId(objectId, calculatedObjectType)]?.includes(action);
}

const getObjectId = (objectId: string, objectType?: PORTAL_OBJECT_TYPE): string => {
  if (objectType && objectType === PORTAL_OBJECT_TYPE.TENANT && !objectId) return DEFAULT_TENANT_ID;
  return objectId;
}

export const getViewListPermissionActionByType = (objectType: PORTAL_OBJECT_TYPE): USER_ACTION | null => {
  switch (objectType) {
    case PORTAL_OBJECT_TYPE.TENANT:
      return USER_ACTION.VLU;
    case PORTAL_OBJECT_TYPE.CLIENT:
      return USER_ACTION.VLC;
    case PORTAL_OBJECT_TYPE.PROJECT:
      return USER_ACTION.VLP;
    default:
      return null;
  }
}
