import { configureStore } from '@reduxjs/toolkit';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { headerReducer } from './slices/header-slice';
import { toastReducer } from './slices/toast-slice';
import { statusCardReducer } from '@/app/redux/slices/status-card-slice';
import { appReducer } from '@/app/redux/slices/app-slice';
import { currentClientReducer } from './slices/current-client-slice';
import { dirtyReducer } from './slices/dirty-slice';
import { currentUserReducer } from './slices/current-user-slice';
import { navigationHistoryReducer } from './slices/navigation-history-slice';
import { statusReducer } from './slices/status-slice';
import { configurationsReducer } from './slices/configuration-slice';

export const store = configureStore({
  reducer: {
    header: headerReducer,
    toast: toastReducer,
    statusCard: statusCardReducer,
    currentClient: currentClientReducer,
    currentUser: currentUserReducer,
    dirty: dirtyReducer,
    navigationHistory: navigationHistoryReducer,
    app: appReducer,
    status: statusReducer,
    configurations: configurationsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
