import { TUserAction } from "@type/users";

export enum USER_ACTION {
  VIEW = "VIEW",
  DELETE = "DELETE",
  MODIFY = "MODIFY",
  CREATE = "CREATE",
  RESPONSE = "RESPONSE",
}

export enum USER_LIST_ACTION {
  ACTIVE = "ACTIVE",
  DE_ACTIVE = "DE_ACTIVE",
  ASSIGNMENTS = "ASSIGNMENTS",
  ON_HOLD = "ON_HOLD",
  RESET_PASSWORD = "RESET_PASSWORD",
  RE_INVITE = "RE_INVITE",
}

export enum USER_TYPE {
  MEDITOLOGY = "MEDITOLOGY",
  CLIENT = "CLIENT",
}

// VLU: View List of Users
// VLC: View List of Clients
// VLP: View List of Projects
export enum USER_ACTION {
  VLU = 'VLU',
  VLC = 'VLC',
  VLP = 'VLP',
  VU = 'VU',
  CU = 'CU',
  MU = 'MU',
  DU = 'DU',
  VC = 'VC',
  CC = 'CC',
  MC = 'MC',
  DC = 'DC',
  VP = 'VP',
  CP = 'CP',
  MP = 'MP',
  DP = 'DP',
  VRL = 'VRL',
  CRL = 'CRL',
  MRL = 'MRL',
  DRL = 'DRL',
  RRL = 'RRL',
  QRL = 'QRL',
  TRL = 'TRL',
  VRC = 'VRC',
  CRC = 'CRC',
  MRC = 'MRC',
  RRC = 'RRC',
  VPD = 'VPD',
  MPD = 'MPD',
  CPD = 'CPD',
  DPD = 'DPD',
  VDM = 'VDM',
  CDM = 'CDM',
  DDM = 'DDM',
  MDM = 'MDM',
  'RI-CCT' = 'RI-CCT',
  'RI-VCT' = 'RI-VCT',
  'RI-MCT' = 'RI-MCT',
  'RI-EEL' = 'RI-EEL',
  'RI-MPIS' = 'RI-MPIS',
  'RI-SPI' = 'RI-SPI',
  'RI-MCP' = 'RI-MCP',
}

export const PAGE_SIZES = [5, 10, 20, 50, 100];

export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  ON_HOLD = 'ON_HOLD',
}

export const DIALOG_INFO_OF_USER: {
  [key in TUserAction]: {
    valueOf: (userName: string, email?: string) => unknown;
  };
} = {
  [USER_LIST_ACTION.ON_HOLD]: {
    valueOf: (userName) => ({
      questionMessage: `Are you sure you want to put ${userName} on hold?`,
      title: 'Put User On Hold',
      detailMessage: 'User will not be able to access Meditology Portal until user record is activated again.',
      primaryBtnText: 'PUT ON HOLD',
      secondaryBtnText: 'CANCEL',
    }),
  },
  [USER_LIST_ACTION.ACTIVE]: {
    valueOf: (userName) => ({
      questionMessage: `Are you sure you want to activate ${userName}?`,
      title: 'Activate User',
      detailMessage: 'User will be able to access Meditology Portal again.',
      primaryBtnText: 'ACTIVATE',
      secondaryBtnText: 'CANCEL',
    }),
  },
  [USER_LIST_ACTION.DE_ACTIVE]: {
    valueOf: (userName) => ({
      questionMessage: `Are you sure you want to deactivate ${userName}?`,
      title: 'Deactivate User',
      detailMessage: `Confirming deactivation will revoke portal access for this user. To restore access, create a new user.`,
      primaryBtnText: 'DEACTIVATE',
      secondaryBtnText: 'CANCEL',
    }),
  },
  [USER_LIST_ACTION.RE_INVITE]: {
    valueOf: (userName, email) => ({
      questionMessage: `Do you want to invite ${userName} to Meditology again?`,
      title: 'Re-Invite User',
      detailMessage: `We’ll send an invitation to <b>${email}</b>`,
      primaryBtnText: 'RE-INVITE',
      secondaryBtnText: 'CANCEL',
    }),
  },
  [USER_LIST_ACTION.RESET_PASSWORD]: {
    valueOf: (userName, email) => ({
      questionMessage: `Do you want to send a password reset link to ${userName}?`,
      title: 'Password Reset',
      detailMessage: `We will send it to the registered email id - <b>${email}</b>`,
      primaryBtnText: 'SEND',
      secondaryBtnText: 'CANCEL',
    }),
  },
  [USER_LIST_ACTION.ASSIGNMENTS]: {
    valueOf: () => ({
    }),
  },
};

export const RESPONSE_ACTION_MESSAGE: {
  [key in (keyof typeof USER_LIST_ACTION)]: {
    valueOf: (userName: string) => {
      successMessage?: string,
      errorMessage?: string
    };
  };
} = {
  [USER_LIST_ACTION.ON_HOLD]: {
    valueOf: (userName: string) => ({
      successMessage: `You’ve put user <b>${userName}</b> on hold successfully`,
      errorMessage: `Unable to put user <b>${userName}</b> on hold`,
    }),
  },
  [USER_LIST_ACTION.ACTIVE]: {
    valueOf: (userName: string) => ({
      successMessage: `You’ve activated user <b>${userName}</b> successfully`,
      errorMessage: `Unable to activate user <b>${userName}</b>`,
    }),
  },
  [USER_LIST_ACTION.DE_ACTIVE]: {
    valueOf: (userName: string) => ({
      successMessage: `You’ve deactivated user <b>${userName}</b> successfully`,
      errorMessage: `Unable to deactivate user <b>${userName}</b>`,
    }),
  },
  [USER_LIST_ACTION.RE_INVITE]: {
    valueOf: (userName: string) => ({
      successMessage: `You’ve re-invited user <b>${userName}</b> successfully`,
      errorMessage: `Unable to re-invite user <b>${userName}</b>`,
    }),
  },
  [USER_LIST_ACTION.RESET_PASSWORD]: {
    valueOf: (userName: string) => ({
      successMessage: `You've sent a password reset link to user <b>${userName}</b> successfully`,
      errorMessage: `Unable to re-set password for user <b>${userName}</b>`,
    }),
  },
  [USER_LIST_ACTION.ASSIGNMENTS]: {
    valueOf: () => ({
    }),
  },
}
