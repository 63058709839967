import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type IFormCheck = {
  formName: string;
  value: boolean;
};

export type IDirtyCheck = IFormCheck[];

const initialState: IDirtyCheck = [];

export const dirtySlice = createSlice({
  name: 'dirty',
  initialState,
  reducers: {
    setDirty: (_state, action: PayloadAction<boolean>) => {
      return [
        {
          formName: 'form',
          value: action.payload,
        },
      ];
    },
    updateMultiDirty: (state: IDirtyCheck, action: PayloadAction<IFormCheck>) => {
      if (state.length === 1 && state[0].formName === 'form') {
        return [action.payload]
      }
      if (state.find((dt) => dt.formName === action.payload.formName)) {
        return state.map((dt) => {
          if (dt.formName === action.payload.formName) {
            return action.payload;
          } else return dt;
        });
      }
      return [...state, action.payload];
    },
    resetDirty: () => {
      return initialState;
    },
  },
});

export const { setDirty, resetDirty, updateMultiDirty } = dirtySlice.actions;
export const dirtyReducer = dirtySlice.reducer;
