'use client'

import { useAuth0 } from '@auth0/auth0-react';
import fetchWithToken from '@helpers/fetch-with-token';

import { useEffect, useState } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import useAuth0Token from '@hooks/use-auth0-token';

interface IUseUserInfo {
  firstName: string | null;
  lastName: string | null;
}

const useUserInfo = (): IUseUserInfo => {
  const { token } = useAuth0Token();
  const { logout, isAuthenticated } = useAuth0();

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  
  useEffect(() => {
    if (!token) {
      return;
    }

    if (!isAuthenticated) {
      void logout();
      return;
    }

    void (async () => {
      try {
        const res = await fetchWithToken<{
          firstName: string;
          lastName: string;
        }>('my-info', token, undefined, false);
        setFirstName(res.firstName);
        setLastName(res.lastName);
      } catch (error) {
        void logout();
      }
    })();
  }, [isAuthenticated, logout, pathname, searchParams, token]);

  return {
    firstName,
    lastName
  };
};

export default useUserInfo;
