export enum PORTAL_OBJECT_TYPE {
  TENANT = "TENANT",
  CLIENT = "CLIENT",
  PROJECT = "PROJECT",
  REQUEST_COLLECTION = "REQUEST_COLLECTION",
  REQUEST_ITEM = "REQUEST_ITEM",
}

export enum SORT_DIR {
  ASC = "ASC",
  DESC = "DESC",
}

export const FORMAT_DATETIME = 'MM/DD/YYYY @ hh:mm A';
export const FORMAT_SHORT_DATE = 'MM/DD/YYYY';
export const FORMAT_DISPLAY_DATE = 'MM / DD / YYYY';

export const DATE_FORMAT_API = 'YYYY-MM-DD';
export const ORDINAL_DATE_FORMAT = 'Do MMMM YYYY, h : mm A';

export const ROOT_URL_INDEX = 0;
export const ROUTE = {
  DEFAULT: '/',
  NOT_FOUND: '/404',
  ASSIGNMENTS: '/assignments',
  CLIENTS: '/clients',
  CLIENT: '/client',
  CREATE_CLIENT: '/client/create',
  USERS: '/users',
  USER: '/user',
  CREATE_USER: '/user/create',
  PROJECTS: '/projects',
  PROJECT: '/project',
  PROJECT_DELIVERABLES: '/project-deliverables',
  CREATE_PROJECT: '/project/create',
  CREATE_REQUEST: '/request-collection',
  CREATE_REQUEST_COLLECTION: '/request-collection/create',
  REQUEST: '/request',
  POPULATION: '/population',
  REQUEST_ITEMS: '/request-items',
  DOCUMENT_MANAGERS: '/document-managers',
};
