import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ICurrentClient } from '@type/clients';

const initialState: ICurrentClient & { shouldMutateCurrentClient?: boolean } = {
  id: '',
  name: '',
  shouldMutateCurrentClient: false
};

export const currentClientSlice = createSlice({
  name: 'currentClient',
  initialState,
  reducers: {
    setCurrentClientState: (state, action: PayloadAction<ICurrentClient>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
    },
    setShouldMutateCurrentClient: (state, action: PayloadAction<boolean>) => {
      state.shouldMutateCurrentClient = action.payload;
    },
    resetCurrentClient: () => {
      return initialState
    }
  },
});

export const { setCurrentClientState, resetCurrentClient, setShouldMutateCurrentClient } = currentClientSlice.actions;
export const currentClientReducer = currentClientSlice.reducer;
