export const LOCAL_STORAGE_KEY = {
  PERMISSION: "permissions",
}

export const API_ENDPOINT = {
  CHECK_PERMISSIONS: 'object-permissions/check',
  CHECK_VIEW_LIST_OBJECT_PERMISSION: 'object-permissions/check-view-list-object',
}

export enum HTTP_STATUS {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}
