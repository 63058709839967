"use client";

import { useAuth0 } from '@auth0/auth0-react';
import { HTTP_STATUS } from '@constants/api';
import { PORTAL_OBJECT_TYPE } from '@constants/portal';
import fetchCorlWithToken, { RequestOptions, ResponseError } from '@helpers/fetch-corl-with-token';
import { DEFAULT_TENANT_ID } from '@helpers/permission-helper';
import _ from 'lodash';
import { useContext } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { PERMISSION_CONTEXT_STATUS, PermissionContext } from '../contexts/permission-context';
import useAuth0Token from './use-auth0-token';
import { PermissionRequest } from './use-permission';

// Custom hook to fetch data from the CORL portal, intercepting the response to handle permissions
function useFetchCorlPortal<T>(url: string | null, options?: RequestOptions<T>, swrOptions?: SWRConfiguration<T>, noConvertJson: boolean = false) {
  const permissionContext = useContext(PermissionContext);
  const {  isAuthenticated } = useAuth0();
  const { token } = useAuth0Token();

  const { data: responseData, error: responseError, isLoading, mutate, isValidating } = useSWR<T, Error>(
    (isAuthenticated && token) ? [url, token] : null,
    ([url, token]) => fetchCorlWithToken(url as string, token as string, options, noConvertJson),
      { 
        onSuccess(data: T) {
          const permissionRequests = options?.permissionRequests;
          if (permissionRequests) {
            let objectIds: string[] = [];
             permissionRequests.forEach(permissionRequest => {
              if (permissionRequest.objectType === PORTAL_OBJECT_TYPE.TENANT) {
                objectIds = [DEFAULT_TENANT_ID];
              }
              else if (permissionRequest.objectIdsFn) {
                objectIds = permissionRequest.objectIdsFn(data);
              }
              else if (!_.isEmpty(permissionRequest.objectIds)) {
                objectIds = permissionRequest.objectIds || [];
              }
              permissionRequest.objectIds = objectIds;
             })
          }
          // Load the permission context with the permission requests
          permissionContext.loadObjectPermissionContext(permissionRequests as PermissionRequest[])
        },
        onError(err) {
          if (options?.mainApi) {
            const error: ResponseError = err;
            if (error.status === HTTP_STATUS.FORBIDDEN) {
              permissionContext.setPermissionContextStatus(PERMISSION_CONTEXT_STATUS.UNAUTHORIZED)
            }
            if (error.status == HTTP_STATUS.BAD_REQUEST || error.status === HTTP_STATUS.NOT_FOUND) {
              permissionContext.setPermissionContextStatus(PERMISSION_CONTEXT_STATUS.NOT_FOUND);
            }
          }
        },
        ...swrOptions
      },
    );

  return {
    data: responseData,
    loading: !token || isLoading,
    error: responseError,
    mutate,
    isValidating
  };
}

export default useFetchCorlPortal;
