import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserInfo {
    firstName: string | null;
    lastName: string | null;
}

const initialState: IUserInfo = {
    firstName: null,
    lastName: null,
  };
  
  export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
      setCurrentUserState: (state, action: PayloadAction<IUserInfo>) => {
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
      },
      resetCurrentUser: () => {
        return initialState
      },
    },
  });
  
  export const { setCurrentUserState, resetCurrentUser } = currentUserSlice.actions;
  export const currentUserReducer = currentUserSlice.reducer;