import { PermissionRequest } from "@hooks/use-permission";

export interface RequestOptions<T=undefined> {
  method?: string;
  mainApi?: boolean;
  permissionRequests?: PermissionRequest<T>[];
  body?: string | FormData | URLSearchParams | Blob | ArrayBufferView | ArrayBuffer | ReadableStream<Uint8Array> | null;
  isFormData?: boolean;
}
export class ResponseError extends Error {
  status?: number;
  errorDetails?: {
    [key: string]: string;
  };
}

const fetchCorlWithToken = <T>(url: string, token: string | null, options?: RequestOptions<T>, noConvertJson?: boolean): Promise<T> => {
  const absoluteUrl = url ? process.env.NEXT_PUBLIC_PORTAL_API_HOST + url : '';

  const headers: HeadersInit | undefined = options?.isFormData ? {
    'Authorization': `Bearer ${token}`
  } : {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  return fetch(absoluteUrl, {
    ...(options || {}),
    method: options?.method ?? 'GET',
    headers,
  }).then(async (r) => {
    if (r.ok) {
      if (noConvertJson) {
        return Promise.resolve(r) as Promise<T>;
      }
      return r.json() as Promise<T>;
    }
    const error: ResponseError = new ResponseError('Error');
    error.status = r.status;
    error.message = await r.text();
    throw error;
  })
    .catch((error: ResponseError) => {
      throw error;
    });
};

export default fetchCorlWithToken;
